<template>
  <div class="bigData">
    <img src="../../assets/product/bigData/1.png" class="headerimg" />
    <div class="advantage background">
      <div class="title">邦伲德全栈云服务</div>
      <div class="advantagelist">
        <div class="advantageinfo">
          <div class="advantageheard">
            <img src="../../assets/product/bigData/2.png" />
            <div class="advantagetext">雄厚技术支撑</div>
          </div>
          <div class="text">
            邦伲德技术专家团队稳定输出充沛的云端大数据技术能力，为客户提供持续性的支撑与服务。
          </div>
        </div>
        <div class="advantageinfo">
          <div class="advantageheard">
            <img src="../../assets/product/bigData/3.png" />
            <div class="advantagetext">丰富领域积累</div>
          </div>
          <div class="text">
            涵盖大数据基础技术、算法、模型及数据方法论在内的丰富数据领域积累，为客户提供完善、成熟的解决方案。
          </div>
        </div>
        <div class="advantageinfo">
          <div class="advantageheard">
            <img src="../../assets/product/bigData/4.png" />
            <div class="advantagetext">广泛场景实现</div>
          </div>
          <div class="text">
            邦伲德社交、游戏、资讯、金融等领域多场景累积的大数据实践经验，为客户提供有效、可靠的业务保障。
          </div>
        </div>
        <div class="advantageinfo">
          <div class="advantageheard">
            <img src="../../assets/product/bigData/5.png" />
            <div class="advantagetext">开放云生态</div>
          </div>
          <div class="text">
            涵盖运维支撑、数据服务、数据产品在内的全链路云端数据合作生态，为客户提供全方位、全覆盖的云端体验。
          </div>
        </div>
      </div>
    </div>

    <div class="productandserve">
      <div class="title">产品及服务矩阵</div>
      <div class="productandservelist">
        <div class="productandservecard">
          <div class="productandservetitle">大数据应用服务</div>
          <div class="productandservetext">企业画像</div>
          <div class="productandservetitle">大数据基础设施</div>
          <div class="productandservetext">商业智能分析 BI</div>
          <div style="display: flex; justify-content: space-between">
            <div class="productandservetext">弹性 MapReduce</div>
            <div class="productandservetext">流计算服务 Oceanus</div>
            <div class="productandservetext">Elasticsearch Service</div>
            <div class="productandservetext">云搜系统</div>
            <div class="productandservetext">云数据仓库 PostgreSQL</div>
          </div>
        </div>
        <div class="productandserveinfo">
          邦伲德提供云端大数据基础设施的支撑，助您构建云端大数据架构与解决方案。使用云端大数据服务，有着如下的优势：
          <div class="productandserveinfotext">
            <div>
              <div class="round"></div>
              快速创建、弹性扩缩容、高性能等产品特性
            </div>
            <div class="productandserveinfoline">
              <div class="round"></div>
              云端运维等基础设施的保障
            </div>
            <div class="productandserveinfoline">
              <div class="round"></div>
              云端数据服务、云端工具服务的无缝对接
            </div>
            <div class="productandserveinfoline">
              <div class="round"></div>
              持续更新的产品功能迭代
            </div>
            <div class="productandserveinfoline">
              <div class="round"></div>
              专业、持续、完善的售后服务支持
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="infrastructure">
      <div class="title">大数据基础设施</div>
      <div class="infrastructurelist">
        <div>
          <div
            class="infrastructuretitle"
            v-for="(item, index) in typeList"
            :class="typeid == index ? 'pitchon' : ''"
            @click="typeid = index"
            :key="index"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="infrastructurecard">
          <div class="infrastructurecardtitle">
            {{ typeList[typeid].title }}
          </div>
          <div class="infrastructureimg">
            <img :src="typeList[typeid].imgurl" />
          </div>
          <div class="infrastructurecardtitle">场景描述</div>
          <div class="infrastructureinfo">
            <div
              class="infrastructureline"
              v-for="(info, index) in typeList[typeid].scencedescriptioninfo"
              :key="index"
            >
              <div class="round"></div>
              {{ info }}
            </div>
          </div>
          <div class="infrastructurecardtitle">典型行业</div>
          <div class="infrastructureinfo">
            <div
              class="infrastructureline"
              v-for="(info, index) in typeList[typeid].infrastructurecardinfo"
              :key="index"
            >
              <div class="round"></div>
              {{ info }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="applicationservice">
      <div class="title">大数据应用服务</div>
      <div class="applicationservicelist">
        <div class="applicationserviceinfo">
          <div>
            <div class="applicationservicetitle">服务介绍</div>
            <div class="applicationservicetext">
              <div>
                <div class="round"></div>
              </div>
              <div class="text">
                企业画像提供全方位企业信息查询服务，构建百亿级企业知识图谱，深度挖掘企业、高管、法人、品牌、产品、地域、产业间的复杂网络关系，洞察企业风险，监控企业发展趋势。
              </div>
            </div>
          </div>
          <div class="applicationservicecard">
            <div class="applicationservicetitle">产品功能</div>
            <div class="applicationservicetext">
              <div>
                <div class="round"></div>
              </div>
              <div class="text">
                产业分析、企业基础信息查询、企业洞察（发展、风险、活跃评估）、企业舆情、企业关系图谱。
              </div>
            </div>
          </div>
          <div class="applicationservicecard">
            <div class="applicationservicetitle">应用场景</div>
            <div class="applicationservicetext">
              <div>
                <div class="round"></div>
              </div>
              <div class="text">智慧城市、智慧园区、金融监管、金融机构等。</div>
            </div>
          </div>
        </div>
        <div class="applicationserviceimg">
          <img src="../../assets/product/bigData/7.png" />
        </div>
        实践经验
      </div>
    </div>

    <div class="client">
      <div class="title">我们的客户</div>
      <div class="clientimg">
        <img src="../../assets/product/bigData/8.png" />
      </div>
    </div>

    <div class="partner">
      <div class="title">合作伙伴</div>
      <div class="partnerlist">
        <div class="partnercard">
          <div class="partnerimg">
            <img src="../../assets/product/bigData/9.png" />
          </div>
          <div class="partnertitle">明略科技</div>
          <div class="partnerinfo">数据中台和企业智能决策平台提供商</div>
        </div>

        <div class="partnercard">
          <div class="partnerimg">
            <img src="../../assets/product/bigData/10.png" />
          </div>
          <div class="partnertitle">东华软件</div>
          <div class="partnerinfo">计算机信息系统集成甲级资质</div>
        </div>

        <div class="partnercard">
          <div class="partnerimg">
            <img src="../../assets/product/bigData/11.png" />
          </div>
          <div class="partnertitle">北明软件</div>
          <div class="partnerinfo">提供综合性 IT 解决方案和云计算服务</div>
        </div>

        <div class="partnercard">
          <div class="partnerimg">
            <img src="../../assets/product/bigData/12.png" />
          </div>
          <div class="partnertitle">长亮科技</div>
          <div class="partnerinfo">全球金融科技解决方案服务商</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      typeid: "0",
      typeList: [
        {
          title: "PB级数仓建设",
          imgurl: require("../../assets/product/bigData/14.png"),
          scencedescriptioninfo: [
            "借助于云端充沛的存储计算能力和丰富的大数据产品线，帮助企业快速适应从初创、发展到成熟过程中的企业级数据仓库技术架构演进。",
            "云端数据仓库产品线提供可靠、高扩展和高自运维能力，将能大幅提升企业 IT 部门的数据运维效能。",
            "邦伲德提供的丰富数据连接器，将会让企业级数据在多基础数据设施之间无缝流转，产生源源不断的数据价值。",
            "云端数据可视化决策产品和AI服务平台，将帮助客户实现数据综合决策的可视化和智能化",
          ],
          infrastructurecardinfo: [
            "电商、金融、零售、游戏发行、资讯、移动应用等。",
          ],
        },
        {
          title: "实时流式计算",
          imgurl: require("../../assets/product/bigData/13.png"),
          scencedescriptioninfo: [
            "云端流计算服务充沛的计算能力可帮助用户分钟级构建点击流分析、电商精准推荐、金融实时风控、物联网（IoT）监控等流计算应用。",
            "托管流计算设施提供实时扩展或缩减计算资源的能力，让用户无需关注基础运维工作，专注于实时服务业务开发。",
            "邦伲德提供配套完善的数据基础设施，用户可便捷地对接云上数据源，自有数据在各个基础设施协作下潜在价值。",
          ],
          infrastructurecardinfo: ["移动应用、资讯、金融、电商、生产制造等。"],
        },
        {
          title: "搜索与检索分析",
          imgurl: require("../../assets/product/bigData/6.png"),
          scencedescriptioninfo: [
            "云端数据搜索设施可用、易伸缩等特性可帮助企业快速搭建稳定的数据搜索服务，并整合到商品搜索、移动应用搜索、BI 分析等已有业务中。",
            "全托管搜索基础设施提供数据处理、分词建立索引、检索串识别、结果排序、数据运营等一站式搜索服务，用户可按需轻松构建定制化搜索服务。",
            "邦伲德为搜索设施集成了安全、机器学习、监控告警等高级服务，帮助用户轻松管理集群、简化运维操作，专注搜索服务的开发。",
          ],
          infrastructurecardinfo: ["电子商务、移动应用、广告媒体等。"],
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.partner {
  padding: 90px;
  background-color: #f5f7fa;

  .partnerlist {
    margin: auto;
    width: 82%;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;

    .partnercard {
      width: 324px;
      height: 198px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(130, 130, 130, 0.5);
      box-sizing: border-box;
      padding-top: 30px;
      text-align: center;

      .partnertitle {
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        line-height: 33px;
        margin-top: 12px;
      }

      .partnerinfo {
        font-size: 14px;
        font-weight: 400;
        color: #616b80;
        line-height: 20px;
        margin-top: 8px;
      }

      .partnerimg {
        img {
          height: 70px;
        }
      }
    }
  }
}

.client {
  padding: 140px;
  text-align: center;

  .clientimg {
    margin-top: 60px;

    img {
      width: 1400px;
    }
  }
}

.applicationservice {
  padding: 90px;
  background-color: #f5f7fa;

  .applicationservicelist {
    display: flex;
    width: 82%;
    margin: auto;
    margin-top: 60px;
    justify-content: space-between;

    .applicationserviceimg {
      img {
        width: 498px;
      }
    }

    .applicationserviceinfo {
      .applicationservicecard {
        margin-top: 40px;
      }

      .applicationservicetext {
        font-size: 14px;
        font-weight: 400;
        color: #616b80;
        line-height: 20px;
        margin-top: 20px;
        display: flex;

        .text {
          width: 776px;
        }
      }

      .applicationservicetitle {
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        line-height: 33px;
      }
    }
  }
}

.advantage {
  padding: 71px;

  .advantagelist {
    display: flex;
    width: 82%;
    margin: auto;
    justify-content: space-between;
    margin-top: 100px;

    .advantageinfo {
      width: 285px;

      .advantageheard {
        text-align: center;
        img {
          height: 53px;
        }

        .advantagetext {
          margin-top: 36px;
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
        }
      }

      .text {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
        color: #9b9c9d;
        line-height: 30px;
      }
    }
  }
}

.productandserve {
  padding: 90px;
  background-color: #f5f7fa;

  .productandservelist {
    width: 82%;
    margin: auto;
    margin-top: 60px;
    display: flex;

    .productandservecard {
      background-color: #cee3ff;
      width: 966px;
      height: 378px;
      padding: 20px;

      .productandservetitle {
        font-size: 24px;
        font-weight: 400;
        color: #2f7ffc;
        line-height: 33px;
      }

      .productandservetext {
        font-size: 14px;
        background-color: #fff;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        padding: 22px;
        margin: 20px 0;
        box-shadow: 0px 2px 4px 0px rgba(130, 130, 130, 0.5);
      }
    }
  }

  .productandserveinfo {
    margin-left: 50px;
    font-size: 14px;
    font-weight: 400;
    color: #616b80;
    line-height: 20px;
    width: 384px;

    .productandserveinfotext {
      margin-top: 30px;

      .productandserveinfoline {
        margin-top: 20px;
      }
    }
  }
}

.infrastructure {
  padding: 140px;

  .infrastructurelist {
    margin-top: 63px;
    display: flex;
    justify-content: center;

    .infrastructuretitle {
      width: 180px;
      padding-right: 74px;
      margin-top: 14px;
      font-size: 24px;
      font-weight: 400;
      line-height: 33px;
      z-index: 1;
      margin-bottom: 60px;
      cursor: pointer;
    }

    .pitchon {
      color: #2f7ffc;
      border-right: 6px solid #2f7ffc;
    }

    .infrastructurecard {
      z-index: -8;
      padding-top: 14px;
      padding-left: 75px;
      border-left: 1px solid #cecece;
      margin-left: -3px;

      .infrastructurecardtitle {
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        line-height: 33px;
      }

      .infrastructureimg {
        margin-top: 20px;
        margin-bottom: 40px;
        border-top: 2px solid #cecece;
        padding-top: 20px;

        img {
          width: 1082px;
        }
      }

      .infrastructureinfo {
        margin-top: 40px;
        margin-bottom: 40px;

        .infrastructureline {
          margin-top: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #616b80;
          line-height: 20px;
        }
      }
    }
  }
}

.title {
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
}

.round {
  display: inline-block;
  background-color: #616b80;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}
</style>
